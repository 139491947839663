@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');

$font-family: Quicksand, Verdana, sans-serif;
$hero-title-font-weight: 500;

$primary-color: #28388A;
$accent-color: #9C60C1;
$warning-color: #97523B;
$important-color: #BD7724;

$main-background: linear-gradient(135deg, $primary-color 0%, $accent-color 100%);

@import 'open-project-mixins';
@import 'jekyll-theme-open-project';

@import 'metanorma-rules';
